<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Authentication</h6>
       
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Social Media Authentication
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12 px-4">
            <div class="relative w-full mb-3">
              <p>{{ message }} <i v-if="inProgress" class="fas fa-spinner fa-spin"></i></p>
              <notification-bar ref="notification"></notification-bar>
            </div>            
          </div>
        </div>       
      </form>
    </div>
  </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "@/components/Notifications/NotificationBar.vue";
import Api from "@/services/Api.js";
//import {Environment} from '@/services/Environment.js';
export default {
  components: {
    NotificationBar
  },  
  data() {
    return {       
      inProgress: false,
      message: null      
    };
  },
  computed: {   
  },
  methods: {
  },
  async mounted() {
    //Extract code and exchange for access token
    //https://developers.facebook.com/docs/instagram-basic-display-api/guides/getting-access-tokens-and-permissions/#step-2--exchange-the-code-for-a-token%5D

    const params = new URLSearchParams(window.location.search);
    const error = params.get("error");
    if (error) {
      const reason = params.get("error_reason");
      const description = params.get("error_description");
      this.$refs.notification.showError(`Authentication failed: ${reason} ${description}`);
      return;
    }
    this.inProgress = true;
    this.message = "Authentication in progress...";
    
    let code = params.get('code');
    code = code.replace("#_", "");
    console.log(code);
    //Exchange for access code...
    const api = Api.from(this.$cookies);
    const res = await api.post('/api/oauth/facebook/exchange', {code});
    console.log(res.data);
    if (res.data.error) {
      this.$refs.notification.showError(`${res.data.error.type} ${res.data.error.message}`);
      this.inProgress = false;
      return;
    }
    const access_token = res.data.access_token;
    if (access_token) {
      this.$refs.notification.show(`Redirecting...`);
      console.log("Storing in userAccessToken localStorage under key facebook_access_token...")
      localStorage.setItem("facebook_access_token", access_token);
      console.log("Done, redirecting...");
      this.inProgress = false;
      this.$router.push("/admin/social-comments");
    }
  }
};
</script>
