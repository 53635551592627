<template>
  <div>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-blueGray-900 text-white']"
    >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">NFT COLLECTIONS</h6>
          <a href="/admin/collection/new">
            <button            
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              New Collection
            </button>
          </a>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
                ]"
              >
                Name
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
                ]"
              >
                Symbol
              </th>
            
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
                ]"
              >
              Description
              </th>     
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
                ]"
              >
              Blockchain
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
                ]"
              >
                Minted
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
                ]"
              >
                Actions
              </th>                           
            </tr>
          </thead>
          <tbody>
            <tr v-for="collection in this.collections" v-bind:key="collection.id">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left items-center"
              >                
                  <a :href="getDetailsHref(collection.id)">{{collection.name}}</a>                
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left items-center"
              >
              {{collection.symbol}}
              </td>
            
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4"
              >
              {{collection.description}}
              </td>                   
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left items-center"
              >
              {{collection.blockchain}}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
              >
                <i v-if="collection.address" class="fas fa-check"></i>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
              >
                <a @click.prevent="deleteCollection(collection.id)" href="#">delete</a>
              </td>
            </tr>          
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import ItemTableDropDown from "@/components/Dropdowns/ItemTableDropDown.vue";
import Api from "@/services/Api.js";
export default {
  data() {
    return {
      collections: null,
      showModal: false
    };
  },
  methods: {
    async deleteCollection(id) {
      if (confirm("Delete collection?")) {
        console.log('deletting...', id);
        const api = Api.from(this.$cookies);
        let res = await api.delete(`/api/collections/${id}`);
        console.log(res.data);
        this.loadCollections();
      }
    },
    async loadCollections() {
      const api = Api.from(this.$cookies);
      let res = await api.get("/api/collections");
      this.collections = res.data;
      console.log(this.collections);    
    },
    getDetailsHref(id) {
      return `/admin/collection/${id}`;
    },
  },
  components: {
  },
  props: ["items", "color"],  
  async mounted() {
    await this.loadCollections();
  }
};
</script>
