<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">SETTINGS</h6>
        <!-- <button
          class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
          Settings
        </button> -->
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Digital Storefront  
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Brand name
              </label>
              <input
              v-model="settings.brand"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="mybrand"
              />
              <span v-if="settings.brand">Preview site will be available at <a :href="previewSiteUrl" target="_blank">{{ previewSiteUrl }}</a></span>
              <br/>
              <button              
              class="mt-3 bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="enablePreview()"              
            >
              Publish Preview Site
            </button>
            <i v-if="progress.enableProgress" class="fas fa-spinner fa-spin"></i>  
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Website domain
              </label>
              <input
                type="text"
                v-model="settings.domain"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="e.g. nft.mybrand.com or digital.mybrand.com"
              />
            </div>     
            <notification-bar ref="domainNotification"></notification-bar>
            <button              
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="enableDomain()"              
            >
              Publish Production Site
            </button>    
            <i v-if="progress.enableDomain" class="fas fa-spinner fa-spin"></i>  
            <!-- <button            
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="testError()"              
            >
              Test Error
            </button>    -->
          </div>   
          
        </div>
        <h6 v-if="settings.domainEnabled" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Settings for your IT Admin
        </h6>
        <div v-if="settings.domainEnabled" class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <i>The person who administers your domain name needs to add a new CNAME DNS record. Provide them the details below. All domain registration companies (registrars) have instructions on how to add new CNAME records</i>
            <br/><br/>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                CNAME Record Name
              </label>
              <input
                disabled
                v-model="settings.domain"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Generated once you enable the storefront"
              />
            </div>           
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                CNAME Record Target
              </label>
              <input
                disabled
                value="datelier-customer-web.pages.dev"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Generated once you enable the storefront"
              />
            </div>      

          </div>
                          
        </div>
        <hr class="mt-6 border-b-1 border-blueGray-300" />
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Brand Information
        </h6>
        <div class="flex flex-wrap">
         
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Instagram Handle
              </label>
              <input
                v-model="settings.handles.instagram"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="@mybrand"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Twitter
              </label>
              <input
                type="email"
                v-model="settings.handles.twitter"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="@mybrand"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                SnapChat
              </label>
              <input
                type="text"
                v-model="settings.handles.snapchat"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="@mybrand"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Tik Tok
              </label>
              <input
                type="text"
                v-model="settings.handles.tiktok"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="@mybrand"
              />
            </div>            
          </div>          
          <div class="w-full lg:w-12/12 px-4">
            <hr class="mt-6 mb-6 border-b-1 border-blueGray-300" />
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Logo URL
              </label>
              <input
              v-model="settings.images.logoUrl"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="e.g. https://mybrand.com/img/logo.png"
              />
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Hero Image URL
              </label>
              <input
                v-model="settings.images.heroUrl"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="e.g. https://mybrand.com/img/hero.png"
              />
            </div>
            <hr class="mt-6 mb-6 border-b-1 border-blueGray-300" />
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Whitelabel Site Title
              </label>
              <input
                v-model="settings.content.title"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Our digital masterpieces"
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Whitelabel Site Subtitle
              </label>
              <input
                v-model="settings.content.subtitle"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Buy with crypto or credit card"
              />
            </div>
            <notification-bar ref="notification"></notification-bar>
            <button     
              v-on:click="save()"       
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>  
            <i v-if="progress.save" class="fas fa-spinner fa-spin"></i>  
          </div>
        </div>

        <!-- <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Contact Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Address
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                City
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="New York"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Country
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="United States"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Postal Code
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value="Postal Code"
              />
            </div>
          </div>
        </div> -->

        <!-- <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          About Me
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                About me
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="4"
              >
                    A beautiful UI Kit and Admin for VueJS & Tailwind CSS. It is Free
                    and Open Source.
                  </textarea
              >
            </div>
          </div>
        </div> -->
      </form>
    </div>
  </div>
</template>
<script>
import Api from "@/services/Api.js";
import NotificationBar from "@/components/Notifications/NotificationBar.vue";

export default {
  data() {
    return {
      progress: {
        enableDomain: false,
        enableProgress: false,
        save: false
      },
      settings: {
        domain: null,
        domainEnabled: false,
        brand: null,
        handles: {
          instagram: null,
          twitter: null,
          snapchat: null,
          tiktok: null
        },
        images: {
          logoUrl: null,
          heroUrl: null
        },
        content: {
          title: null,
          subtitle: null
        }
      },
    };
  },
  computed: {
     previewSiteUrl() {
      if (!this.settings) {
        return null;
      }
      return `https://${this.settings.brand}.d-atelier.xyz`;
     }
  },
  methods: {
    async save() {
      this.progress.save = true;
      try {
        const api = Api.from(this.$cookies);
        let res = await api.post("/api/settings", this.settings);
        console.log(res.data);
        this.$refs.notification.show("Settings saved");
      } catch (e) {
        this.$refs.domainNotification.showError(e);
      }
      this.progress.save = false;
    },  
    async enableDomain() {
      this.progress.enableDomain = true;
      const api = Api.from(this.$cookies);
      try {             
        if (this.settings.domain) {
          let res = await api.post("/api/settings/domains", {
            domain: this.settings.domain,
          });
          console.log(res.data);
          this.$refs.domainNotification.show("Domain enabled. Provide the settings below to your IT admin");
          this.settings.domainEnabled = true;
        }        
      } catch (e) {
        this.$refs.domainNotification.showError(e);
      }
      this.progress.enableDomain = false;
    },
    async enablePreview() {
      const api = Api.from(this.$cookies);
      if (this.settings.brand) {
        this.progress.enableProgress = true;
        try {
          const cnameUrl = `${this.settings.brand}.d-atelier.xyz`;
          await api.post("/api/settings/domains", {
            domain: cnameUrl
          });  
          this.$refs.domainNotification.show(`${cnameUrl} enabled.`);
        } catch (e) {
          this.$refs.domainNotification.showError(e);
        }
        this.progress.enableProgress = false;
      }
    },
    async checkDomain() {
      //See if the domain is enabled on Cloudflrae
      if (!this.settings.domain) {
        this.settings.domainEnabled = false;
        return;
      }
      const api = Api.from(this.$cookies);
      let res = await api.get(`/api/settings/domains/${this.settings.domain}`);
      console.log(res.data);
      this.settings.domainEnabled = res.data.domainEnabled;
    },
    async testError() {
      this.$refs.domainNotification.show("Error test", true);
    }
  },
  components: {    
    NotificationBar
  },
  async mounted() {
    const api = Api.from(this.$cookies);
    let res = await api.get("/api/settings");
    if (!res.data) {
      return;
    }
    this.settings = res.data || this.settings;
    console.log(this.settings);
    this.checkDomain();
  }
};
</script>