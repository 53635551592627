<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <collection-details />
    </div>
  </div>
</template>
<script>
import CollectionDetails from "@/components/Cards/CollectionDetails.vue";

export default {
  components: {
    CollectionDetails    
  }
};
</script>
