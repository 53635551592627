<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">Augmented Reality (AR) Filters</h6>          
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            For TikTok, Instagram and SnapChat
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4">
              <div class="relative w-full mb-3">
                <div class="flex flex-wrap">
                  <div class="w-full sm:w-12/12 md:w-12/12">
                    Create Augmented Reality (AR) filters of your items so your customers can record themselves and generate content for you.
                    See <a style="text-decoration: underline;" href="https://lens.snapchat.com/3d281f60b732432c95bafec54116b7f6" target="_blank">example</a>.
                    <ol class="mt-4">
                      <li>1. Upload photos from front, back and each side of the item</li>
                      <li>2. Add a description</li>
                      <li>3. Once complete, your AR filter will appear in your library</li>
                    </ol>
                    <hr class="mt-4" />
                  </div>
                  
                  <div class="mt-4 w-full sm:w-12/12 md:w-12/12">
                    <form method="POST" action="" enctype="multipart/form-data">
                        <input type="file" name="file" id="file">
                        <br/>
                        <button
                          class="mt-2 bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          style="width:10rem"
                          type="submit"
                        >
                          <i class="fas fa-upload"></i> Upload
                        </button>
                        <textarea
                          type="text"
                          class="mt-4 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          rows="4"
                          placeholder="Enter instructions for the 3D designer and modeller here"
                        >
                        </textarea>
                        <label class="inline-flex items-center cursor-pointer">
                        <input
                          id=""
                          type="checkbox"
                          class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span class="ml-2 text-sm text-blueGray-600">
                          Tiktok
                        </span>
                      </label>
                      <br/>
                      <label class="inline-flex items-center cursor-pointer">
                        <input
                          id=""
                          type="checkbox"
                          class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span class="ml-2 text-sm text-blueGray-600">
                          Snapchat
                        </span>
                      </label>
                      <br/>
                      <label class="inline-flex items-center cursor-pointer">
                        <input
                          id=""
                          type="checkbox"
                          class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span class="ml-2 text-sm text-blueGray-600">
                          Instagram
                        </span>
                      </label>
                      <br/>
                       <label class="inline-flex items-center cursor-pointer">
                        <input
                          id=""
                          type="checkbox"
                          class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span class="ml-2 text-sm text-blueGray-600">
                          Include animation
                        </span>
                      </label>
                      <br/>
                      <button
                          class="mt-2 bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          style="width:10rem"
                          type="submit"
                        >
                          Submit for design
                        </button>
                      </form>   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
      </div>        
    </div>
  </div>
</template>
<script>
//import Api from "@/services/Api.js";
//import { ModelGltf } from 'vue-3d-model';
//import { vue3dLoader } from "vue-3d-loader";
///assets/img/Snapchat-Logo.jpg
import snapchatLogo from "@/assets/img/snapchat-logo.png";
import tiktokLogo from "@/assets/img/tiktok-logo.png";
import instagramLogo from "@/assets/img/instagram-logo.png";
export default {
  components: {
    //vue3dLoader
  },
  data() {
    return {
      snapchatLogo, tiktokLogo, instagramLogo,
      collections: [],
      isMounted: false,      
    }
  },
  computed: {
     loadComponent() {
      return () => import('@google/model-viewer')
     }
  },
  mounted() {
    console.log('Filters.vue:mounted()');
    this.isMounted = true;
     this.loadComponent()    
  }

};
</script>
