<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">NFT Details</h6>
       
      </div>
    </div>

    
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Media
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-8/12 px-4">
            <div class="relative w-full mb-3">
              <div class="flex flex-wrap">
                <div class="w-full sm:w-12/12 md:w-12/12">
                  <div class="relative inline-flex align-middle w-full">
                    <div v-if="!isMediaUploaded" >
                      <form method="POST" :action="formActionUrl" enctype="multipart/form-data">
                        <input type="file" name="file" id="file">
                        <br/>
                        <button
                          class="mt-2 bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          style="width:10rem"
                          type="submit"
                        >
                          <i class="fas fa-upload"></i> Upload
                        </button>
                      </form>     
                    </div>
                    <div v-if="isMediaUploaded">
                      <img
                        class="mt-4"              
                        :alt="upload.filename"
                        :src="blob"                  
                      />                
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 v-if="isMediaUploaded" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Collection
        </h6>
        <div v-if="isMediaUploaded" class="flex flex-wrap">
          <div class="w-full lg:w-8/12 px-4">
            <div class="relative w-full mb-3">
              <div class="flex flex-wrap">
                <div class="w-full sm:w-12/12 md:w-12/12">
                  <div class="relative inline-flex align-middle w-full">
                    <select v-model="item.collectionId" name="collectionId" id="collectionId" @change="onCollectionChange">
                      <option v-for="collection in collections" v-bind:key="collection.id" :value="collection.id">{{collection.name}} ({{ collection.blockchain }})<span v-if="!collection.address">(not minted)</span> </option>
                    </select>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 v-if="isMediaUploaded" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          NFT Details
        </h6>
        <div v-if="isMediaUploaded" class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Name*
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Snake Skin Jacket - Anaconda Series"
                v-model="item.name"
              />
            </div>
          </div>
         
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Description*
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="4"
                placeholder="The inaugural Snake Skin Jacket as part of Forever 21's debut NFT Anaconda series"
                v-model="item.description"
              >
                
                  </textarea
              >
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Supply*
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="How many of this NFT will exist? 1, 10, 100 etc."
                v-model="item.supply"
              />              
            </div>
          </div>
        </div>

        <h6 v-if="isMediaUploaded" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Additional Properties
        </h6>
        <div v-if="isMediaUploaded" class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Animation URL
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="https://mysite/animation.mp4"
                v-model="item.properties.animationUrl"
              />
            </div>
          </div>
         
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                External URL
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="https://mysite/nft-details.html"
                v-model="item.properties.externalUrl"
              />              
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Background color (HEX)
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="#FFFFFF"
                v-model="item.properties.backgroundColor"
              />              
            </div>
          </div>
        </div>

        <h6 v-if="isMediaUploaded" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Augmented Reality
        </h6>
        <div v-if="isMediaUploaded" class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Snap Lens URL
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Provided by D'ATELIER or your 3D designer"
                v-model="item.arData.snapchat.url"
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                TikTok URL
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Provided by your D'ATELIER or your 3D designer"
                v-model="item.arData.tiktok.url"
              />              
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Instagram URL
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Provided by D'ATELIER or your 3D designer"
                v-model="item.arData.instagram.url"
              />     
            </div>
           
          </div>
         

        </div>


        <!-- <h6 v-if="isMediaUploaded" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Custom Attributes (<a href="https://docs.opensea.io/docs/metadata-standards#attributes" target="_blank">Read more</a>)
        </h6>        
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Strength"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Value
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="10"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                &nbsp;
              </label>
              <button 
                v-on:click="addCustomAttribute()"
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
              Add
            </button>
            </div>
          </div>
        </div> -->

        <h6 v-if="isMinted" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Metadata
        </h6>
        <div v-if="isMinted" class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <!-- <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="owner"
              >
                Owner
              </label>
             <input
                id="owner"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Snake Skin Jacket - Anaconda Series"
                v-model="item.nftData.owner"
              />
            </div> -->
          </div>
          </div>
          <div v-if="isMinted" class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">   
         
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="owner"
              >
                URLs
              </label>
              Transaction:
              <a :href="urls.transactionUrl" target="_blank">
                <i class="fas fa-link"></i>
              </a>
              Token:
              <a :href="urls.tokenUrl" target="_blank">
                <i class="fas fa-link"></i>
              </a>
              Metadata URL:
              <a :href="urls.metadataUrl" target="_blank">
                <i class="fas fa-link"></i>
              </a>
              Media URL:
              <a :href="urls.mediaUrl" target="_blank">
                <i class="fas fa-link"></i>
              </a>
            </div>
          </div>
        </div>

        <div v-if="isMediaUploaded" class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3 mt-3">
          
            <notification-bar ref="notification"></notification-bar>

            <button
              v-on:click="save()"
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
            <button 
              v-on:click="mint()"
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
              <i class="fas fa-code mr-2"></i> Mint NFT  
            </button>
            <i v-if="inProgress" class="fas fa-spinner fa-spin"></i>
          </div>
        </div>

        <div v-if="isMinted" class="flex flex-wrap">
          <h6 v-if="isMinted" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Channels / Listings
         </h6>
          <div class="w-full lg:w-12/12 px-4">                          
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="blockhain"
            >
            Destination
            </label>
            <select v-model="listing.destination" name="blockhain" id="blockchain">
              <option value="direct">Digital Storefront (white-labeled)</option>
              <option disabled value="opensea">Opensea (coming soon)</option>
              <option disabled value="decentraland">D'ATELIER Marketplace (coming soon)</option>
            </select>
            <br/>
            <br/>
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="blockhain"
            >
            Price and Currency
            </label>
            <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full lg:w-4/12 ease-linear transition-all duration-150"
                placeholder="100.00"
                v-model="listing.price"
              />
              &nbsp;
            <select v-model="listing.currency" name="storage" id="storage">
              <option value="usdc">USDC</option>
              <option disabled value="usdt">USDT</option>
              <option disabled value="eth">ETH</option>
              <option disabled value="matic">MATIC</option>
            </select>
            <br/>
            <br/>
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="blockhain"
            >
            Quantity to list (supply: {{ item.supply }})
            </label>
            <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full lg:w-4/12 ease-linear transition-all duration-150"
                placeholder="e.g. 10. How many of the total supply do you want to list?"
                v-model="listing.quantity"
              />
              <br/><br/>
            <!-- <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="blockhain"
            >
            NFT Rights
            </label>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id=""
                type="checkbox"
                class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
              />
              <span class="ml-2 text-sm text-blueGray-600">
                Customer entitled to photo-realistic dressed photo for social media. E.g. Instagram
              </span>
            </label>
            <br/>
            <label disabled class="inline-flex items-center cursor-pointer">
              <input
                id=""
                type="checkbox"
                class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                disabled
              />
              <span class="ml-2 text-sm text-blueGray-600">
                Customer entitled to photo-realistic dressed video for social media. E.g. TikTok / YouTube (coming soon)
              </span>
            </label> -->
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3 mt-3">
   
              <notification-bar ref="notification"></notification-bar>

              <button  
                v-on:click="pushListing()"              
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
              <i class="fas fa-file-import mr-2"></i> Push Listing
              </button>
              <i v-if="listingInProgress" class="fas fa-spinner fa-spin"></i>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import Api from "@/services/Api.js";
import BlockchainExplorerService from "@/services/BlockchainExplorerService";
import NotificationBar from "@/components/Notifications/NotificationBar.vue";
export default {
  components: {
    NotificationBar
  },
  data() {
    return {
      collections: [],
      collection: {},
      item: {},
      upload: {},
      listing: {destination: "direct", currency: "usdc"},
      blob: null,
      inProgress: false,
      listingInProgress: false
    };
  },
  computed: {
    isCollectionMinted() {
      return this.collection && this.collection.address != null;
    },
    isMinted() {
      return this.item && this.item.nftData;
    },
    isSaved() {
      return this.item != null && this.item.id >= 0;
    },
    is3DModel() {
      return this.upload != null && (this.upload.filename.endswith(".glb") || this.upload.filename.endswith(".gltf"));
    },
    urls() {
      if (this.item && this.item.urls) {
        return this.item.urls;
      }
      return {};
    },
    isMediaUploaded() {
      return this.item && this.item.uploadId != null;
    },
    formActionUrl() {
      const api = Api.from(this.$cookies);
      return api.getFullUrl("/api/uploads");
    }
  },
  methods: {
    async mint() {

      if (!this.isCollectionMinted) {
        alert("Collection must be minted for minting an NFT.")
        return;
      }
      if (!this.isSaved) {
        alert("Save the NFT before minting")
        return;
      }

      if (this.isMinted) {
        alert("NFT already minted.");
        return;
      }

      if (!confirm("No changes can be made after minting. Are you sure?")) {
        return;
      }
      console.log("Minting NFT!");
      try {
        this.inProgress = true;
        const api = Api.from(this.$cookies);
        let res = await api.post(`/api/web3/nfts/${this.item.id}`);
        this.$refs.notification.show("NFT minted!");
        console.log(JSON.stringify(res.data));
        console.log(typeof res.data);
        //let nftOutput = res.data;

        this.item.nftData = res.data.nftData;
        this.item.receipt =  res.data.receipt;
        this.item.urls = this.buildUrls(this.item, this.collection);
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      this.inProgress = false;
     
    },
    async save() {
      console.log(JSON.stringify(this.item));
      if (!this.item.collectionId || !this.item.name || !this.item.description || !this.item.uploadId) {
        alert("Collection, name, description and media are required");
        return;
      }
      try {
        const api = Api.from(this.$cookies);
        if (!this.isSaved) {
          this.inProgress = true;
          let res = await api.post("/api/items", this.item);
          console.log(res.data);
          this.item.id = parseInt(res.data);

          let resCollection = await api.get(`/api/collections/${this.item.collectionId}`);
          console.log(resCollection.data);
          this.collection = resCollection.data;      

          this.$refs.notification.show("NFT details saved. You can now mint.");
        } else {
          let res = await api.post(`/api/items/:${this.item.id}`, this.item);
          this.$refs.notification.show("Item details not already on blockchain updated.");
          console.log(res.data);
          return;
        }
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      this.inProgress = false;
    },
    async loadBlob(id) {
      console.log(`loadBlob(${id})`);
      const api = Api.from(this.$cookies);
      let res = await api.get(`/api/blobs/${id}`, {
        responseType: "arraybuffer"
      });
      this.blob = URL.createObjectURL( new Blob( [ res.data ]));
    },
    async loadMedia(uploadId) {
      console.log(`loadMedia(${uploadId})`);
      const api = Api.from(this.$cookies);
      let resUpload = await api.get(`/api/uploads/${uploadId}`);
      //console.log(resUpload.data);
      this.upload = resUpload.data;
      this.loadBlob(uploadId);
    },
    buildUrls(item, collection) {
      console.log('buildUrls');
      console.log(JSON.stringify(item));
      let urls = {}
      let blockchainExplorerService = new BlockchainExplorerService(collection.blockchain);
      urls.transactionUrl = blockchainExplorerService.getTransactionUrl(item.receipt.transactionHash);
      urls.tokenUrl = blockchainExplorerService.getTokenUrl(collection.address, item.nftData.metadata.id);
      urls.metadataUrl = item.nftData.metadata.uri;
      urls.mediaUrl = item.nftData.metadata.image;
      return urls;
    },
    findCollection(id) {
      for (const collection of this.collections) {
        if (collection.id == id) {
          return collection;
        }
      }
      return null;
    },
    async pushListing() {
      if (!this.listing.price) {
        this.$refs.notification.showError("Price in USD required");
        return;
      }

      let currencyAddress = "";
      switch (this.collection.blockchain) {
        case  "mumbai":
          //listed on the circle website: https://developers.circle.com/developer/docs/usdc-on-testnet
          currencyAddress = "0x0fa8781a83e46826621b3bc094ea2a0212e71b23";          
          //used thirdweb dashboard uses
          //currencyAddress = "0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747"
          break;
        case "polygon":
          currencyAddress = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
          break;
        default:
          throw new Error("Only mumbai and polygon USDC currently supported");
      }

       const listing = {
        itemId: this.item.id,
        blockchain: this.collection.blockchain,
        assetContractAddress: this.collection.address,
        marketplaceAddress: this.collection.marketplaceAddress,
        tokenId: this.item.nftData.metadata.id,
        startTimestamp: new Date(),
        listingDurationInSeconds: 2630000, //1 month
        quantity: this.listing.quantity,
        currencyContractAddress: currencyAddress,
        buyoutPricePerToken: parseFloat(this.listing.price).toFixed(2), //divided by currency decimals. e.g. 6 for USDC
      };
      console.log(JSON.stringify(listing));
      try {
        this.listingInProgress = true;
        const api = Api.from(this.$cookies);
        let res = await api.post('/api/web3/listings', listing, {timeout: 300000});
        console.log(JSON.stringify(res.data));
        let jobId = res.data.jobId;
        let done = false;
        //10mins
        for (let i=0; i < 120; i++) {
          console.log(`${i}/120`);
          let resStatus = await api.get(`/api/jobs/${jobId}`);
          console.log(resStatus.data);
          const jobStatus = resStatus.data;
          if (jobStatus.status == "done") {
            done = true;
            break;
          }
          if (jobStatus.status == "error") {
            throw new Error("Failed to push listing. Contact suppport");
          }
          //Wait 5 secs
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
        if (done) {
          this.$refs.notification.show("Listing pushed.");
        } else {
          this.$refs.notification.showError("Listing timed out. Contact support to verify status.");  
        }
        
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      this.listingInProgress = false;
    },
    onCollectionChange() {
      console.log(`Collection: ${this.item.collectionId}`);
    }
  },
  async mounted() {
    const api = Api.from(this.$cookies);
    let res = await api.get("/api/collections");
    this.collections = res.data;
    console.log(this.collections);

    if (this.$route.params.id == "new") {
      console.log("New item. Initializing new object");
      let uploadId = this.$route.query.uploadId;
      if (uploadId) {
        console.log("Have uploadId");
        this.item = {
          name: null,
          description: null,
          collectionId: null,
          uploadId: uploadId,
          arData: {
            snapchat: {},
            tiktok: {},
            instagram: {}
          },
          properties: {},
          attributes: {}
        }
        this.loadMedia(this.item.uploadId);
      }
    } else {
      let id = this.$route.params.id;
      console.log(`Loading existing item: ${id}`)
      const api = Api.from(this.$cookies);
      let res = await api.get(`/api/items/${id}`);
      this.item = res.data;  
      console.log("item:", JSON.stringify(this.item, null, 2));

      //Decorate with additional data
      let resCollection = await api.get(`/api/collections/${this.item.collectionId}`);
      console.log("collection:", JSON.stringify(resCollection.data, null, 2));
      this.collection = resCollection.data;   
      this.onCollectionChange();   

      this.loadMedia(this.item.uploadId);
     
      if (this.isMinted) {
        this.item.urls = this.buildUrls(this.item, this.collection);
      }

    }
  }
};
</script>
