<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        :href="detailsHref"
        v-on:click="viewDetails()"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        View Details
      </a>
      <a
        href="#"
        v-on:click="deleteItem()"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Delete
      </a>
      <!-- <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Something else here
      </a> -->
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import Api from "@/services/Api.js";
export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  computed: {
    detailsHref() {
      return `/admin/item/${this.item.id}`;
    }
  },
  props: ["item"],
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    viewDetails() {
      console.log(`Navigating to /item/${this.item.id}`);
    },
    async deleteItem() {      
      console.log(`Deleting item:${this.item.id}`);
      let api = Api.from(this.$cookies);
      let res = api.delete(`/api/items/${this.item.id}`);
      console.log(res.data);
      //TODO: raise a deleted event so the parent can remove from the table
    }
  },
  mounted() {
    //console.log(`item: ${this.item}`)
  }
};
</script>
