<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <item-table :items="items" :collections="collections" color="light"/>
    </div>
  </div>
</template>
<script>
import ItemTable from "@/components/Cards/ItemTable.vue";
import Api from "@/services/Api.js";
export default {
  components: {
    ItemTable,
  },
  data() {
    return {
      items: [],
      collections: []
    }
  },
  async mounted() {
    console.log('here');
    const api = Api.from(this.$cookies);
    let res = await api.get("/api/items");
    this.items = res.data;

    let res2 = await api.get("/api/collections");
    this.collections = res2.data;
  }

};
</script>
