<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <item-details />
    </div>
  </div>
</template>
<script>
import ItemDetails from "@/components/Cards/ItemDetails.vue";

export default {
  components: {
    ItemDetails    
  },
};
</script>
