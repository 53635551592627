<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">Augmented Reality (AR) Filters</h6>          
          </div>
        </div>        
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Your Augemented Reality Designs
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4">
              <div class="relative w-full mb-3">
                <div class="flex flex-wrap">
                  <div class="w-full sm:w-12/12 md:w-12/12">
                    <h5 class="text-md font-bold leading-normal mt-0 mb-2">
                      Orbit
                    </h5>
                    <!-- <video width="300"
                    preload="auto" loop autoplay muted>
                    <source src="https://pub-24aa3d2c5b7e466d9a92dced9c7dfec6.r2.dev/meshika-demo-white.webm">
                    Your browser does not support the video tag.
                  </video> -->
                  <h6 class="text-sm font-bold leading-normal mt-0 mb-2">
                      3D Model
                  </h6>
                  <model-viewer style="width: 512px; height: 512px;" v-if="isMounted"
                    alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum" 
                    src="/hat_copy_lp_1.glb" 
                    ar shadow-intensity="1" camera-controls touch-action="pan-y">
                  </model-viewer>
                  </div>
                  <div class="w-full sm:w-12/12 md:w-12/12">   
                    <h6 class="text-sm font-bold leading-normal mt-0 mb-2">
                      Animation
                  </h6>                 
                    <video width="300"
                    preload="auto" loop autoplay muted>
                    <source src="https://pub-24aa3d2c5b7e466d9a92dced9c7dfec6.r2.dev/meshika-demo-white.webm">
                    Your browser does not support the video tag.
                  </video>
                  </div>
                  <div class="w-full sm:w-12/12 md:w-12/12 mt-4">   
                    <h6 class="text-sm font-bold leading-normal mt-0 mb-2">
                      Social Media
                    </h6>   
                    <div class="container pr-4 mx-auto">
                        <div class="flex flex-wrap">
                          <a href="https://lens.snapchat.com/3d281f60b732432c95bafec54116b7f6" target="_blank" class="mr-4">
                            <img :src="snapchatLogo" width="96"/>
                          </a>
                          <a target="_blank">
                            <img style=" opacity: 0.2; filter: alpha(opacity=20);" :src="tiktokLogo" width="96" class="mr-4"/>
                          </a>
                          <a target="_blank">
                            <img style=" opacity: 0.2; filter: alpha(opacity=20);" :src="instagramLogo" width="96" class="mr-4"/>
                          </a>
                        </div>
                    </div>              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>          
       
      </div>      
    </div>
  </div>
</template>
<script>
//import Api from "@/services/Api.js";
//import { ModelGltf } from 'vue-3d-model';
//import { vue3dLoader } from "vue-3d-loader";
///assets/img/Snapchat-Logo.jpg
import snapchatLogo from "@/assets/img/snapchat-logo.png";
import tiktokLogo from "@/assets/img/tiktok-logo.png";
import instagramLogo from "@/assets/img/instagram-logo.png";
export default {
  components: {
    //vue3dLoader
  },
  data() {
    return {
      snapchatLogo, tiktokLogo, instagramLogo,
      collections: [],
      isMounted: false,      
    }
  },
  computed: {
     loadComponent() {
      return () => import('@google/model-viewer')
     }
  },
  mounted() {
    console.log('Filters.vue:mounted()');
    this.isMounted = true;
     this.loadComponent()    
  }

};
</script>
