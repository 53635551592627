<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Artificial Intelligence</h6>
       
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Reply to Social Media Comments
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12 px-4">
            <div class="relative w-full mb-3">
              <notification-bar ref="notification"></notification-bar>
            </div>            
          </div>
        </div>       
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12 px-4">
            <!-- <div v-if="!hasAccessToken" class="relative w-full mb-3"> -->
              <div  class="relative w-full mb-3">
              <p class="mb-2">
                Connect your Instagram account for D'ATELIER to read comments and generate replies. You can review before any replies are made
              </p>
              <a :href="facebookLoginUrl">
                <button  
                  v-on:click="connectToInstagram()"              
                  class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                <i class="fab fa-instagram mr-2"></i> Connect to Instagram
                </button>             
              </a>              
            </div>            
          </div>
        </div>   
        <div class="flex flex-wrap">

          <div v-for="facebookAccount in facebookAccounts" v-bind:key="facebookAccount.id" class="relative w-full mb-3">
            <b>{{ facebookAccount.name }} account <!--{{ facebookAccount.id }}--></b>
            
            <div>
              <button  
                v-on:click="suggestAll()"              
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
              <i class="fas fa-comment mr-2"></i> Suggest All Replies
              <i v-if="suggestAllInProgress" class="fas fa-spinner fa-spin"></i>
            </button>
            <button  
                v-on:click="postAll()"              
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
              <i class="fas fa-reply-all mr-2"></i> Post All Replies
              <i v-if="suggestAllInProgress" class="fas fa-spinner fa-spin"></i>
            </button>
            </div>
            <div v-if="facebookAccount.media">
              <table v-for="media in facebookAccount.media" v-bind:key="media.id" class="mt-4">
                <!-- <tr>
                  <th>Post</th>
                  <th>Comments</th>
                </tr> -->
                <tr>
                  <td style="width: 25%" :rowspan="getRowspan(media)"><img :src="media.media_url" :alt="media.caption" /></td>
                  <td >
                    <span v-if="media.caption" style="font-weight: bold;">{{ media.caption.substring(0, 200) }}...</span>
                    
                    <hr class="my-4"/>
                  </td>
                </tr>
                <tr v-show="media.comments" v-for="comment in media.comments.data" v-bind:key="comment.id">
                  <td>
                    <div class="messages yours">
                      <div class="message">
                        {{ comment.text }}
                      </div>
                    </div>
                    <button                          
                        v-on:click="suggestReply(comment)"              
                        class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                      <i class="fas fa-comment mr-2"></i> Reply
                    </button>

                    <table v-if="comment.replies && comment.replies.data">
                      <tr v-for="reply in comment.replies.data" v-bind:key="reply.id">
                        <td>
                          <!-- <i v-if="reply.ownerReply" class="fas fa-reply mr-2 ml-4"></i>{{ reply.text }}<span v-if="reply.ownerReply"><i class="text-xs">(owner reply)</i></span> -->
                          <div class="messages" :class="{mine: reply.ownerReply, yours: !reply.ownerReply}">
                            <div class="message">
                              {{ reply.text }}
                            </div>
                          </div>
                          <button  
                              v-if="!reply.ownerReply"                        
                              v-on:click="suggestReply(comment)"              
                              class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                            >
                            <i class="fas fa-comment mr-2"></i> Reply
                          </button>
                        </td>
                      </tr>
                    </table>

                    <!-- <span v-if="comment.ownerReplied"><i class="text-xs">(Replied)</i></span> -->
                    <i v-if="comment.inProgress" class="fas fa-spinner fa-spin"></i>
                    <br/>
                    <div class="w-full">
                      &nbsp;&nbsp;&nbsp;<input class="ml-8 mt-2 text-sm lg:w-9/12" v-if="comment.reply" type="text" v-model="comment.reply" />
                    &nbsp;
                    <button 
                      v-if="comment.suggestedAnswer" 
                      v-on:click="postReply(comment, comment.reply)"              
                      class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                    <i class="fas fa-reply mr-2"></i> Post
                    </button>
                    <i v-if="comment.replyInProgress" class="fas fa-spinner fa-spin"></i>
                    </div>
                  </td>
                </tr>
            
              </table>              
            </div>
          </div>
        </div>       
      </form>
    </div>
  </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "@/components/Notifications/NotificationBar.vue";
import Api from "@/services/Api.js";
import {Environment} from '@/services/Environment.js';
import axios from 'axios';
export default {
  components: {
    NotificationBar
  },  
  data() {
    return {       
      inProgress: false,
      suggestAllInProgress: false,
      hasAccessToken: false,
      accessToken: null,
      facebookAccounts:[]
    };
  },
  computed: {
    hasAccounts() {
      return this.facebookAccounts != null && this.facebookAccounts.length > 0;
    },
    facebookLoginUrl() {
      //const api = Api.from(this.$cookies);
      const environment = new Environment();
      const uiBase = environment.uiUrlBase;
      const redirectUri = `${uiBase}/admin/auth/exchange`;
      console.log(redirectUri);
      //From Facebook Dashboard. Public values. The secret is server side and used to exchange for a token
      const clientId = "242404965012944";
      const configId = "910615250024960";
      return `https://www.facebook.com/v16.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=1&config_id=${configId}`;
    }
  },
  methods: {   
    getRowspan(media) {
      if (media && media.comments && media.comments.data && media.comments.data.length > 0) {
        return media.comments.data.length + 1;
      }
      return 1;      
    },
    async postReply(comment, reply) {
      console.log("reply:", reply);
      //POST /{ig-comment-id}/replies?message={message}
      comment.replyInProgress = true;
      try {
        const facebookUrlBase = "https://graph.facebook.com/v16.0";
        const url = `${facebookUrlBase}/${comment.id}/replies?message=${reply}&access_token=${this.accessToken}`;
        console.log(url);
        let res = await axios.post(url);
        console.log(res.data);
        comment.ownerReplied = true;
      } catch (e) {
        console.error(e);
      }
      comment.replyInProgress = false;
    },
    async suggestReply(comment) {
      comment.inProgress = true;
      try {
        const api = Api.from(this.$cookies);
        let commentThread = `${comment.text}`;
        if (comment.replies && comment.replies.data) {
          commentThread = `${commentThread} ${comment.replies.data.map(r => r.text)}`;
        } 
        console.log("commentThread");
        console.log(commentThread)
        const res = await api.post("/api/ai/comments", {comment: commentThread});
        console.log(res.data);
        comment.suggestedAnswer = res.data.answer;
        comment.reply = comment.suggestedAnswer; //user can change this

      } catch (e) {
        console.error(e);
      }
      comment.inProgress = false;
    },
    async suggestAll() {
      for (let account of this.facebookAccounts) {
        for (let media of account.media) {
          for (let comment of media.comments.data) {
            if (comment.ownerReplied) {
              continue;
            }
            this.suggestReply(comment);
          }
        }
      }
    },
    async postAll() {
      for (let account of this.facebookAccounts) {
        for (let media of account.media) {
          for (let comment of media.comments.data) {
            if (!comment.reply) {
              continue;
            }
            this.postReply(comment, comment.reply);
          }
        }
      }
    },
    async loadComments() {
      console.log("Loading comments...");
      const facebookUrlBase = "https://graph.facebook.com/v16.0";
      const url = `${facebookUrlBase}/me/accounts?fields=id,name,connected_instagram_account&access_token=${this.accessToken}`;
      try {
        let res = await axios.get(url);
        console.log(res.data.data);
        this.facebookAccounts = res.data.data;        
        //Only render accounts connected to instagram
        this.facebookAccounts = this.facebookAccounts.filter(acc => acc.connected_instagram_account);
        for (let facebookAccount of this.facebookAccounts) {
          const instagramUserId = facebookAccount.connected_instagram_account.id;
          try {
            const resInstagramMedia = await axios.get(`${facebookUrlBase}/${facebookAccount.connected_instagram_account.id}/media?fields=id,thumbnail_url,media_url,caption,comments{replies{user,text},text}&access_token=${this.accessToken}`);
            //const resInstagramStories = await axios.get(`${facebookUrlBase}/${facebookAccount.connected_instagram_account.id}/stories?access_token=${this.accessToken}`);
            console.log(resInstagramMedia);
            facebookAccount.media = resInstagramMedia.data.data;
            console.log("media")
            console.log(facebookAccount.media);
            for (let media of facebookAccount.media) {
              if (!media.comments || !media.comments.data) {
                media.comments = [];
                continue;
              }
              for (let comment of media.comments.data) {
                media.hasComments = true;
                if (comment.replies) {
                  console.log('have replies')
                  comment.replies.data = comment.replies.data.reverse();
                  for (let reply of comment.replies.data) {
                    if (!reply.user) {
                      continue;
                    }
                    console.log(`does ${reply.user.id} == ${instagramUserId}`);
                    if (reply.user.id == instagramUserId) {
                      //flag that there is at least one owner reply in the comment thread
                      comment.ownerReplied = true;
                      //flag that this particular reply was from the owner
                      reply.ownerReply = true;
                    }
                  }
                } 
              }
            }
          } catch (e) {
            this.$refs.notification.showError(e);
          }
        }        
      } catch (e) {
        this.$refs.notification.showError(e);
      }
    }
  },
  mounted() {
    //Check for a fb_access_token and check if it's valid
    this.accessToken = localStorage.getItem("facebook_access_token");
    if (this.accessToken) {
      console.log(`Have a token! ${this.accessToken} Validate... then call get comments`);
      this.hasAccessToken = true;
      this.loadComments();
    } else {
      console.log("User needs to auth");
    }    
  }
};
</script>
<style scoped>
.chat {
  width: 300px;
  border: solid 1px #EEE;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

</style>
