export class Environment {
    constructor() {
        console.log(`process.env.NODE_ENV=${process.env.NODE_ENV}`);
        if (process.env.NODE_ENV == "development") {
            this.env = Env.DEV;
        } else {
            this.env = Env.PROD;
        }
        console.log(`this.env=${this.env}`)
    }

    get uiUrlBase() {
        if (this.env == Env.DEV) {
            return "http://localhost:8080";
        } else {
            return "https://d-atelier.xyz";
        }
    }

    get loginUrl() {
        if (this.env == Env.DEV) {
            return "http://localhost:8080/auth/login";
        } else {
            return "https://d-atelier.xyz/auth/login";
        }
    }

    get stytchToken() {
        if (this.env == Env.DEV) {
            return "public-token-test-17c9ff56-61f0-4843-81ed-a547e1c89f38";
        } else {
            return "public-token-live-eecce8ad-1046-4a50-9eab-144612ee6fcb";
        }
    }

    get redirectUriBase() {
       return `${this.loginUrl}?redirect=`;
    }
}

export const Env = Object.freeze({
    DEV: "development",
    PROD: "production"
})
