<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6 pb-64">
      <h6 class="mb-2 text-blueGray-700 text-xl font-bold">BRAND PORTAL</h6>
      <hr/>
      <table class="mt-8">
        <!-- <tr>
          <td>
            <a href="/admin/upload">
              <button
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              style="width:12rem"
              type="button"
              >
                <i class="fas fa-upload"></i> Upload media
              </button>
            </a>
          </td>
          <td>        
            <span>Upload your high- or low-poly 3D model or a pre-processed image or video </span>
          </td>
        </tr> -->


        <tr>
          <td> 
            <a href="/admin/settings">
              <button
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                style="width:12rem"
                type="button"
              >
              <i class="fas fa-tools"></i> Settings
              </button>
            </a>
          </td>
          <td>
            <span>Configure settings and preferences like default blockchains and destinations</span>
          </td>
        </tr>

     
        <tr>
          <td>
            <a href="/admin/collections">
              <button
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                style="width:12rem"
                type="button"
              >
              <i class="fas fa-layer-group"></i> NFT Collections
              </button>
            </a>
          </td>
          <td><span>View your existing collections</span></td>
        </tr>
        <tr>
          <td>
            <a href="/admin/items">
              <button
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                style="width:12rem"
                type="button"
              >
              <i class="fas fa-images"></i> NFTs
              </button>
            </a>
          </td>
          <td><span>View your existing NFTs and their status</span></td>
        </tr>
       
        <tr>
          <td> 
            <a href="/marketplace">
              <button
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                style="width:12rem"
                type="button"
              >
              <i class="fas fa-vr-cardboard"></i> Augmented Reality
              </button>
            </a>
          </td>
          <td>
            <span>Create augmented reality filters for SnapChat and TikTok</span>
          </td>
        </tr>

        <tr>
          <td> 
            <a href="/marketplace">
              <button
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                style="width:12rem"
                type="button"
              >
              <i class="fas fa-file"></i> AR Library
              </button>
            </a>
          </td>
          <td>
            <span>Your augmented reality library</span>
          </td>
        </tr>

      </table>
      
      <div class="mt-4">
        
        
      </div>
      <div class="mt-4">
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "portal-page",
  components: {
   
  },
  async mounted() {
    console.log('here');
  }
};
</script>
