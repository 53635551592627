<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-blueGray-900 text-white']"
  >
  <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">NFTs</h6>
          <a href="/admin/item/new">
            <button            
              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              New NFT
            </button>
          </a>
        </div>
      </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
              ]"
            >
              Item
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
              ]"
            >
              Id
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
              ]"
            >
              Collection
            </th>  
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
              ]"
            >
              Blockchain
            </th>           
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
              ]"
            >
              Minted
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-blueGray-800 text-blueGray-300 border-blueGray-700',
              ]"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in this.items" v-bind:key="item.id">
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
            >
              
                <a :href="getDetailsHref(item.id)">{{item.name}}</a>
              
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
            {{item.id}}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
            {{item.collectionName}}
            </td>   
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
            {{item.collectionBlockchain}}
            </td>           
            <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
            >
              <i v-if="item.nftData" class="fas fa-check"></i>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <item-table-drop-down :item="item"/>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ItemTableDropDown from "@/components/Dropdowns/ItemTableDropDown.vue";

export default {
  
  data() {
    return {
      //items: null
    };
  },
  methods: {
    getDetailsHref(id) {
      return `/admin/item/${id}`;
    },
    findCollection(id) {
      for (const collection of this.collections) {
        if (collection.id == id) {
          return collection;
        }
      }
      return null;
    }
  },
  components: {
    ItemTableDropDown,
  },
  props: ["items", "color", "collections"],    
  async mounted() {
    //console.log(`mounted: ${this.items}`);
  },
  async updated() {
    if (!(this.items && this.items.length > 0)) {
      return;
    }
    if (!(this.collections && this.collections.length > 0)) {
      return;
    }
    //console.log(`updated: ${this.items.length} items and ${this.collections.length} collections`);
    for (const item of this.items) {
      const collection = this.findCollection(item.collectionId);
      if (collection) {
        item.collectionName = collection.name;
        item.collectionBlockchain = collection.blockchain;
      }      
    }
  }
};
</script>
