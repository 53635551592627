<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <collection-table :collections="collections" color="light"/>
    </div>
  </div>
</template>
<script>
import CollectionTable from "@/components/Cards/CollectionTable.vue";
import Api from "@/services/Api.js";
export default {
  components: {
    CollectionTable,
  },
  data() {
    return {
      collections: []
    }
  },
  async mounted() {
    console.log('here');
    let api = Api.from(this.$cookies);
    let res = await api.get("/api/collections");
    console.log(JSON.stringify(res.data));
    this.collections = res.data;
  }

};
</script>
