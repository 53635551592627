<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Artificial Intelligence</h6>
       
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Generate Social Media Images
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Prompt
              </label>
              Create me an image of                                  
            </div>
            <div class="relative w-full mb-3">              
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Film still of an elderly black man playing chess, medium shot, mid-shot"
                v-model="prompt"
              />
            </div>
            <!-- <div class="relative w-full mb-3">              
              in <select v-model="styleType" name="styleType" id="styleType">
              <option value="Photorealistic">Photo realistic</option>
              <option value="Impressionist">Impressionist</option>
              <option value="Cartoon">Cartoon</option>
              <option value="Pop Art">Pop Art</option>
              <option value="Tweet Thread">Tweet Thread</option>
              <option value="LinkedIn Article">LinkedIn Article</option>
              <option value="Generic Post">Generic Post</option>
            </select> style
            </div> -->
          </div>
          
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3 mt-3">
   
              <notification-bar ref="notification"></notification-bar>

              <button  
                v-on:click="generatedPost()"              
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
              <i class="fas fa-image mr-2"></i> Generate Images
              </button>             
              <i v-if="inProgress" class="fas fa-spinner fa-spin"></i>
            </div>
          </div> 
          <div class="w-full lg:w-9/12 px-4 mt-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              <i class="fas fa-robot"></i> AI Generated Content
              </label>
              <span v-if="inProgress">{{ this.answer }}</span>
              <table>
                <tr><td> <img v-if="url1" :src="url1" /></td><td> <img v-if="url2" :src="url2" /></td></tr>
                <tr><td> <img v-if="url3" :src="url3" /></td><td> <img v-if="url4" :src="url4" /></td></tr>
              </table>
             
            </div>
          </div>
        </div>       
      </form>
    </div>
  </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "@/components/Notifications/NotificationBar.vue";
import Api from "@/services/Api.js";
export default {
  components: {
    NotificationBar
  },  
  data() {
    return {       
      inProgress: false,
      prompt: null,
      answer: null,
      styleType: "Photorealistic",
      url1: null,
      url2: null,
      url3: null,
      url4: null
    };
  },
  computed: {
  },
  methods: {
    async generatedPost() {
      if (!this.prompt) {
        this.$refs.notification.showError("Enter a prompt");
        return;
      }
      this.inProgress = true;
      this.answer = `Generating images...`;
      try {

        const api = Api.from(this.$cookies);
        //const normalizedPrompt = `Create me an image of ${this.prompt} in ${this.styleType} style`
        const normalizedPrompt = this.prompt;
        let res = await api.post('/api/ai/images', {
          num: 4,
          prompt: normalizedPrompt
        });
        console.log(res.data);
        this.url1 = res.data[0].url;
        this.url2 = res.data[1].url;
        this.url3 = res.data[2].url;
        this.url4 = res.data[3].url;
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      this.inProgress = false;
    }
  },
  mounted() {

  }
};
</script>
