<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Artificial Intelligence</h6>
       
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <!-- <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Reply to Social Media Messages
        </h6> -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12 px-4">
            <div class="relative w-full mb-3">
              <notification-bar ref="notification"></notification-bar>
            </div>            
          </div>
        </div>       
        <div class="flex flex-wrap">
            <!-- <div v-if="!hasAccessToken" class="relative w-full mb-3"> -->
              <div  class="relative w-full mb-3">
              <p class="mb-2">
                Connect your Instagram account for D'ATELIER to read messages and generate replies. You can review before any replies are made
              </p>
              <a :href="facebookLoginUrl">
                <button  
                  v-on:click="connectToInstagram()"              
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  style="padding-top: 0.7rem"
                  type="button"
                >
                <i class="fab fa-instagram mr-2"></i> Authenticate
                </button>             
              </a>  
              <select class="rounded py-1" v-model="platform" @change="onPlatformChanged(platform)" name="platform" id="platform" >
                <option value="instagram">Instagram</option>
                <option value="facebook">Facebook</option>
              </select>            
          </div>
        </div>   

        <!-- <div class="container px-4 mx-auto">
          <div class="flex flex-wrap">
            <div class="w-3/12 px-4">
              <span  class="text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
                x
              </span>
            </div>
            <div class="w-9/12 px-4">
              <span class="text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
                y
              </span>
            </div>
          </div>
        </div> -->

        <div class="flex flex-wrap">
         
          <br/>
          
          <div v-for="facebookAccount in facebookAccounts" v-bind:key="facebookAccount.id" class="relative w-full mb-3">
            <b>{{ facebookAccount.name }} account <!--{{ facebookAccount.id }}--></b><i v-if="inProgress" class="fas fa-spinner fa-spin"></i>
            <table v-for="conversation in facebookAccount.conversations" v-bind:key="conversation.id" class="mt-4 w-full">
              <tr>
                <th align="left">Thread with {{ conversation.messages[0].from.name || conversation.messages[0].from.username }} <img v-if="conversation.profilePictureUrl" :src="conversation.profilePictureUrl" /> </th>
                <th align="left" class="px-4"></th>
              </tr>
              <tr v-for="message in conversation.messages" v-bind:key="message.id">
                <td :align="message.fromSelf ? 'right' : 'left'" style="width: 50%">
                  <!-- <span v-if="!message.fromSelf">From: {{ message.from.name || message.from.username }}<br/></span> -->
                  <div class="messages" :class="{mine: message.fromSelf, yours: !message.fromSelf}">
                    <div class="message">
                      {{ message.message }}
                    </div>
                  </div>
                  
                </td>
                <td class="px-4" style="vertical-align:bottom">
                  <textarea v-if="message.reply && !message.ownerReplied" rows="10" type="text" :value="message.reply" class="rounded" style="width: 100%; font-size: smaller;" /><br/>
                  <button  
                      v-if="!message.ownerReplied && !message.fromSelf"
                      v-on:click="suggestReply(message)"              
                      class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                    Suggest Reply
                  </button>
                  <span v-if="message.ownerReplied"><i class="text-xs">(Replied)</i></span>
                  <i v-if="message.inProgress" class="fas fa-spinner fa-spin"></i>
                  <button 
                    v-if="message.suggestedAnswer && !message.ownerReplied" 
                    v-on:click="postReply(message, message.reply)"              
                    class="text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                  <i class="fas fa-reply mr-2"></i> Reply
                  </button>
                  <i v-if="message.replyInProgress" class="fas fa-spinner fa-spin"></i>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>            
                  <div class="px-4 py-4">
                    <button  
                      v-if="hasConversations"
                      v-on:click="refresh(facebookAccount.id)"              
                      class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                    Refresh...
                    </button>   
                    <i v-if="facebookAccount.loadConversationsInProgress" class="fas fa-spinner fa-spin"></i>   
                  </div>
              </td>
              </tr>
            </table>
          </div>
        </div>       
      </form>
    </div>
  </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "@/components/Notifications/NotificationBar.vue";
import Api from "@/services/Api.js";
import {Environment} from '@/services/Environment.js';
import axios from 'axios';
export default {
  components: {
    NotificationBar
  },  
  data() {
    return {       
      platform: "instagram",
      inProgress: false,
      suggestAllInProgress: false,
      hasAccessToken: false,
      accessToken: null,
      pageAccessToken: null,
      facebookAccounts:[]
    };
  },
  computed: {
    hasAccounts() {
      return this.facebookAccounts != null && this.facebookAccounts.length > 0;
    },
    hasConversations() {
      if (!this.hasAccounts) {
        return false;
      }
      for (let account of this.facebookAccounts) {
        if (account.conversations && account.conversations.length > 0) {
          return true
        }
      }
      return false;
    },
    facebookLoginUrl() {
      //const api = Api.from(this.$cookies);
      const environment = new Environment();
      const uiBase = environment.uiUrlBase;
      const redirectUri = `${uiBase}/admin/auth/exchange`;
      console.log(redirectUri);
      //From Facebook Dashboard. Public values. The secret is server side and used to exchange for a token
      const clientId = "242404965012944";
      const configId = "910615250024960";
      return `https://www.facebook.com/v16.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=1&config_id=${configId}`;
    }
  },
  methods: {   
    async onPlatformChanged(platform) {
      console.log(`onPlatformChanged: ${platform}`);
      this.loadFacebookAccounts(platform);
    },
    async postReply(message, reply) {
      message.replyInProgress = true;
      try {
        const facebookUrlBase = "https://graph.facebook.com/v16.0";
        const url = `${facebookUrlBase}/me/messages?access_token=${this.pageAccessToken}`;
        const replyPayload = {
          recipient: {id: message.from.id},
          message: {text: reply}
        };
        console.log(JSON.stringify(replyPayload, null, 2));
        let res = await axios.post(url, replyPayload);
        console.log(res.data);
        message.ownerReplied = true;
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      message.replyInProgress = false;    
    },
    async suggestReply(message) {
      message.inProgress = true;
      try {
        const api = Api.from(this.$cookies);
        const res = await api.post("/api/ai/comments", {comment: message.message});
        console.log(res.data);
        message.suggestedAnswer = res.data.answer;
        message.reply = message.suggestedAnswer; //user can change this

      } catch (e) {
        this.$refs.notification.showError(e);
      }
      message.inProgress = false;
    },
    findAccount(id) {
      for (let account of this.facebookAccounts) {
        if (account.id == id) {
          console.log(`Found account ${id}`);
          return account;
        }
      }
      return null;
    },
    async refresh(facebookAccountId) {
      const account = this.findAccount(facebookAccountId);
      this.loadConversations(account);
    },
    async loadFacebookAccounts() {
      console.log("Loading messages...");
      this.inProgress = true;
      //"https://graph.facebook.com/v16.0/107118149007152/conversations?fields=messages%7Bto%2Cmessage%2Cfrom%7D&access_token=EAADcd03U1dABAEy6pXmV09rbdSxZABWoYrwXjDiiF8tyVZAbfJRy2ncLCkNK65VFXnVvcBCItIbWmgZCcNHMt06Gf7t7D75N60epfJ50N67IAW8SHqLhroB2wFZAtFZCrRrZBH5R5xBuvYMf321POcvB2iwdaCxmiPK4Wp1uQfZCEzErDKNpiaCJ4hHZBHt28QNvDZAYRa8QXZAIlQkstHLDKb"
      const facebookUrlBase = "https://graph.facebook.com/v16.0";
      const url = `${facebookUrlBase}/me/accounts?fields=id,name,connected_instagram_account&access_token=${this.accessToken}`;
      //Get a page token from the user token (conversations require a page token)
      //GET "https://graph.facebook.com/107118149007152?fields=access_token&access_token=EAADcd03U1dABADAVZBzWODv4jhEffSkgvZCohM111ORz2VDdEapKtcFfTBnUsukbPLl7eYTnKCTtSR0ZCTNit8LiW8Y65qMrnfHPmMXdUZCv16dkCS9DTntiXtxJMG6l0V92u6wFK83t2TG64xKiT1OvFHMsZCbukOhS1EPdq307wVANzJLbDbU4LcBbSWkum0jshXZAJlz8Yw7HXh5yaoEuxwDj3RDK9Neoc5opIV3I5ht17PeslN"
      try {
        let res = await axios.get(url);
        console.log(res.data.data);
        this.facebookAccounts = res.data.data;        
        this.facebookAccounts = this.facebookAccounts.filter(acc => acc.connected_instagram_account);
        if (this.facebookAccounts && this.facebookAccounts.length > 0) {
          let firstFacebookAccount = this.facebookAccounts[0];
          const resPageToken = await axios.get(`${facebookUrlBase}/${firstFacebookAccount.id}?fields=access_token&access_token=${this.accessToken}`);
          this.pageAccessToken = resPageToken.data.access_token;
          console.log("Storing in pageAccessToken localStorage under key facebook_page_access_token...")
          localStorage.setItem("facebook_page_access_token", this.pageAccessToken);
        }
        for (let facebookAccount of this.facebookAccounts) {
          await this.loadConversations(facebookAccount)                  
        }        
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      this.inProgress = false;
    },
    async loadConversations(facebookAccount) {
      facebookAccount.loadConversationsInProgress = true;
      try {
          const facebookUrlBase = "https://graph.facebook.com/v16.0";
          //TODO: load participants first and hten only the messages for the selected participant
          //114828797876296/conversations?fields=participants&platform=instagram
          let conversationUrl = `${facebookUrlBase}/${facebookAccount.id}/conversations?fields=messages{to,message,from}&access_token=${this.pageAccessToken}`;
          if (this.platform == "instagram") {
            conversationUrl = `${facebookUrlBase}/${facebookAccount.id}/conversations?platform=instagram&fields=messages{to,message,from,attachments{file_url,image_data},story,sticker,id},link,participants&access_token=${this.pageAccessToken}`;
          }
          const resConversations = await axios.get(conversationUrl);
          const instagramUserId = facebookAccount.connected_instagram_account.id;
          console.log("resConversations");
          console.log(resConversations);
          facebookAccount.conversations = resConversations.data.data  ;
          for (let conversation of facebookAccount.conversations) {
            console.log("conversation");
            console.log(conversation);
            conversation.messages = conversation.messages.data || [];
            conversation.participants = conversation.participants.data || [];
            conversation.messages = conversation.messages.filter(m => m.message)
            for (let message of conversation.messages) {
              if (message.from.id == facebookAccount.id || message.from.id == instagramUserId) {
                message.fromSelf = true;
              }
              // try {
              //   if (!conversation.profilePictureUrl) {
              //     console.log("message", JSON.stringify(message));
              //     const profileUrl = `${facebookUrlBase}/${message.from.id}?platform=instagram&fields=profile_picture_url&access_token=${this.pageAccessToken}`;
              //     console.log("profileUrl", profileUrl)
              //     let resProfile = await axios.get(profileUrl);
              //     console.log("profile", resProfile.data);
              //     conversation.profilePictureUrl = resProfile.data.profile_picture_url;
              //   }
              // } catch (e) {
              //   console.log("can't get profile")
              // }                
            }
            conversation.messages = conversation.messages.reverse();
          }
        } catch (e) {
          this.$refs.notification.showError(e);    
        }  
        facebookAccount.loadConversationsInProgress = false;
    }
  },
  mounted() {
    //Check for a fb_access_token and check if it's valid
    this.accessToken = localStorage.getItem("facebook_access_token");
    if (this.accessToken) {
      console.log(`Have a token! ${this.accessToken} Validate... then call get comments`);
      this.hasAccessToken = true;
      this.loadFacebookAccounts();
    } else {
      console.log("User needs to auth");
    }
    
  }
};
</script>
<style scoped>
.chat {
  width: 300px;
  border: solid 1px #EEE;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

</style>
