<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >  
    <div class="rounded-t bg-white mb-0 px-6 py-6">      
      <h6 class="mb-2 text-blueGray-700 text-xl font-bold">Upload</h6>
      <hr/>
      <div v-if="!isSuccessVisible">
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Upload your media. This could be a .png, a high-poly or low-poly 3D model.
              </p>

        <div class="mt-4">        
          <form method="POST" action="http://datelier-local:8787/api/uploads" enctype="multipart/form-data">
            <input class="mt-2" type="file" name="file" id="file">
            <br/>
            <button
              class="mt-2 bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              style="width:10rem"
              type="submit"
            >
              <i class="fas fa-upload"></i> Upload
            </button>
          </form>             
        </div>
      </div>
      <div v-if="isSuccessVisible" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-blueGray-500">
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-bell"></i>
        </span>
        &nbsp;
        <span class="inline-block align-middle mr-8">
          <b class="capitalize"> Success!</b> Model uploaded successfully
        </span>
        <button class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" v-on:click="closeAlert()">
          <span>×</span>
        </button>
      </div>
      <div v-if="isSuccessVisible">
        View its progress <a style="text-decoration:underline" href="/admin/items">here</a>
      </div>

      
    </div>
  </div>
</template>
<script>
//const axios = require('axios').default;
export default {
  name: "upload-page",
  components: {
   
  },
  data() {
    return {
      isSuccessVisible: false
    }
  },
  methods: {
    closeAlert: function(){
      this.isSuccessVisible = false;
    }
  },
  async mounted() {
    if (this.$route.query.success == 'true') {
      this.isSuccessVisible = true;
    }
  }
};
</script>
