<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Artificial Intelligence</h6>
       
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Generate Social Media Content
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Prompt
              </label>
              Write me a  <select v-model="contentType" name="contentType" id="contentType">
              <option value="Facebook Post">Facebook Post</option>
              <option value="Instagram Post">Instagram Post</option>
              <option value="Tweet">Tweet</option>
              <option value="Tweet Thread">Tweet Thread</option>
              <option value="LinkedIn Article">LinkedIn Article</option>
              <option value="Generic Post">Generic Post</option>
            </select>
             about                         
            </div>
            <div class="relative w-full mb-3">              
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="why jeggings are better than leggings"
                v-model="prompt"
              />
            </div>
          </div>
          
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3 mt-3">
   
              <notification-bar ref="notification"></notification-bar>

              <button  
                v-on:click="generatedPost()"              
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
              <i class="fas fa-comment mr-2"></i> Generate Content
              </button>             
              <i v-if="inProgress" class="fas fa-spinner fa-spin"></i>
            </div>
          </div> 
          <div class="w-full lg:w-9/12 px-4 mt-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              <i class="fas fa-robot"></i> AI Generated Content
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="8"
                placeholder="As a fashion-savvy individual, I am always on the lookout for the latest and greatest styles. And, when it comes to bottoms, there is no denying that jeggings have taken the fashion world by storm. In fact, I argue that jeggings are better than jeans in several ways, making them the ultimate choice for any fashion-forward individual.

First and foremost, jeggings offer unparalleled comfort compared to traditional jeans. Made of stretchy material that hugs your curves, jeggings allow for complete freedom of movement without any uncomfortable bunching or bunching. This means that you can wear jeggings all day long without experiencing any discomfort, making them the perfect choice for those busy days where you're constantly on the go.

Additionally, jeggings are incredibly versatile. They can be dressed up or down, depending on the occasion. Throw on a blazer and some heels, and you're ready for a night out on the town. Or, dress them down with a cozy sweater and sneakers for a casual day out with friends. With jeggings, the possibilities are endless.

Finally, I argue that jeggings are simply more flattering than jeans. The form-fitting nature of jeggings accentuates your curves in all the right places, while traditional jeans can sometimes create unflattering bulges or gaps. Jeggings also offer a sleek and streamlined look, making them perfect for pairing with crop tops and other form-fitting tops.

In conclusion, I believe jeggings are the superior choice when it comes to fashion-forward bottoms. With their comfort, versatility, and flattering fit, jeggings are the perfect addition to any wardrobe. So, if you haven't jumped on the jeggings bandwagon yet, I highly recommend giving them a try. Trust me, you won't regret it!"
                v-model="answer"
              >                
                  </textarea
              >
            </div>
          </div>
        </div>       
      </form>
    </div>
  </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "@/components/Notifications/NotificationBar.vue";
import Api from "@/services/Api.js";
export default {
  components: {
    NotificationBar
  },  
  data() {
    return {       
      inProgress: false,
      prompt: null,
      answer: null,
      contentType: "Facebook Post"
    };
  },
  computed: {
  },
  methods: {
    async generatedPost() {
      if (!this.prompt) {
        this.$refs.notification.showError("Enter a prompt");
        return;
      }
      this.inProgress = true;
      this.answer = `Generating ${this.contentType}...`;
      try {

        const api = Api.from(this.$cookies);
        let res = await api.post('/api/ai/chat', {
          contentType: this.contentType,
          prompt: this.prompt
        });
        this.answer = res.data.answer;
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      this.inProgress = false;
    }
  },
  mounted() {

  }
};
</script>
