<template>
  <div class="container flex content-center items-center justify-center">
    <div class="mx-4 w-full md:w-4/12 lg:w-4/12 xl:w-4/12">
      <img class="rounded-lg" :src="hero" />
    </div>
    
    <div class="w-full md:w-4/12 lg:w-4/12 xl:w-4/12">
        <div id="stytch-sdk"></div>
        <div v-if="token">Logging in...</div>
    </div>
  </div>
    <!-- <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in with
              </h6>
            </div>
            <div class="btn-wrapper text-center">
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="github" />
                Github
              </button>
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="google" />
                Google
              </button>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Or sign in with credentials</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Create new account</small>
            </router-link>
          </div>
        </div>
      </div>
    </div> -->
  <!-- </div> -->
</template>
<script>
import { StytchUIClient } from '@stytch/vanilla-js';
import hero from "@/assets/img/datelier-blue.jpg";
import Api from "@/services/Api.js";
import {Environment} from '@/services/Environment.js';
export default {
  data() {
    return {
      hero,
      token: null,
      tokenType: null
    };
  },
  async mounted() {    

    const environment = new Environment();
    const stytch = new StytchUIClient(environment.stytchToken);
    let redirectUri = environment.loginUrl;
    console.log("redirectUri: ", redirectUri);

    const params = new URLSearchParams(window.location.search);
    this.tokenType = params.get("stytch_token_type");
    this.token = params.get('token');
    if (this.token) {
      try {
        let res = null;
        console.log(this.tokenType);
        if (this.tokenType == "oauth") {
          res = await stytch.oauth.authenticate(this.token, {session_duration_minutes: 1440});
        } else {
          res = await stytch.magicLinks.authenticate(this.token, {session_duration_minutes: 1440});          
        } 
        console.log(JSON.stringify(res, null, 2));
        let user = res.user;
        user.metadata = { apps: ["brand-portal"], roles: ["admin"] }; //brands use the dashboard, end-users use the generated site.
        let api = Api.from(this.$cookies)
        await api.post("/api/users", res.user);
        console.log("User exists or created successfully.");
        this.$router.push('/admin/portal');

      } catch (e) {
        console.error(e);
        alert("Login failed. See console.");
      }
    }
    
    const styles = {
        fontFamily: '"Helvetica New", Helvetica, sans-serif',
        width: '321px',
        primaryColor: '#0577CA',
      };
  
      stytch.mountLogin({
        elementId: '#stytch-sdk',
        styles,
        config: {
          products: ['emailMagicLinks', 'oauth'],
          emailMagicLinksOptions: {
            loginRedirectURL: redirectUri,
            loginExpirationMinutes: 30,
            signupRedirectURL: redirectUri,
            signupExpirationMinutes: 30,
            createUserAsPending: true,
          },
          oauthOptions: {
            providers: [{type: 'google', position: 'floating'}],
            loginRedirectURL: redirectUri,
            signupRedirectURL: redirectUri
          },
        },
      });



  }
};
</script>
