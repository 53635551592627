import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import VueCookies from 'vue-cookies'

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Portal from "@/views/admin/Portal.vue";
import Test from "@/views/admin/Test.vue";
import Upload from "@/views/admin/Upload.vue";
import Settings from "@/views/admin/Settings.vue";
import Item from "@/views/admin/Item.vue";
import Items from "@/views/admin/Items.vue";
import Collections from "@/views/admin/Collections.vue";
import SocialContent from "@/views/admin/SocialContent.vue";
import SocialImages from "@/views/admin/SocialImages.vue";
import SocialComments from "@/views/admin/SocialComments.vue";
import SocialMessages from "@/views/admin/SocialMessages.vue";
import Filters from "@/views/admin/Filters.vue";
import Library from "@/views/admin/Library.vue";
import Collection from "@/views/admin/Collection.vue";
import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import Exchange from "@/views/auth/Exchange.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";

// auth
import { StytchUIClient } from '@stytch/vanilla-js';
import {Environment, Env} from './services/Environment';

// routes

console.log(`ENV:${process.env.NODE_ENV}`); 

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/portal",
        component: Portal,
      },
      {
        path: "/admin/test",
        component: Test,
      },
      {
        path: "/admin/upload",
        component: Upload,
      },
      {
        path: "/admin/item/:id",
        component: Item,
      },
      {
        path: "/admin/collection/:id",
        component: Collection,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/items",
        component: Items,
      },
      {
        path: "/admin/collections",
        component: Collections,
      },
      {
        path: "/admin/social-content",
        component: SocialContent,
      },
      {
        path: "/admin/social-images",
        component: SocialImages,
      },
      {
        path: "/admin/social-comments",
        component: SocialComments,
      },
      {
        path: "/admin/social-messages",
        component: SocialMessages,
      },
      {
        path: "/admin/auth/exchange",
        component: Exchange,
      },
      {
        path: "/admin/filters",
        component: Filters,
      },
      {
        path: "/admin/library",
        component: Library,
      },
      {
        path: "/admin/maps",
        component: Maps,
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      }     
    ],
  },
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/",
    component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(`Navigate to: ${to.path} requiresAuth:${to.meta.requiresAuth}`);
  if (to.meta.requiresAuth) {
    let stytchToken = 'public-token-test-17c9ff56-61f0-4843-81ed-a547e1c89f38';
    let environment = new Environment();
    if (environment.env == Env.PROD) {
      stytchToken = 'public-token-live-eecce8ad-1046-4a50-9eab-144612ee6fcb';
    }    
    console.log("stytchToken: " + stytchToken);
    const stytch = new StytchUIClient(stytchToken);
    let session = stytch.session.getSync();
    
    //TODO: need to check if expired?
    if (!session) {
      console.log("No session, redirecting to login...")
      return next("/auth/login");
    }
  }
  next();
});

/* const app = */createApp(App)
  .use(VueCookies, { expires: '7d'})
  .use(router)
  .mount("#app");