<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Collection Details</h6>
       
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Metadata
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Winter Jacket Series 2022"
                v-model="collection.name"                
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Symbol
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="F21-WJ2022"
                v-model="collection.symbol"
              />
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Description
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="4"
                placeholder="The inaugural Forever 21 winter jacket series for 2022"
                v-model="collection.description"
              >                
                  </textarea
              >
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Wallet Information
        </h6>
        <div class="flex flex-wrap">
          <!-- <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Primary Sales Recipient
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="0xabc...123"
                v-model="collection.primaryRecipient"
              />
            </div>
          </div> -->
          <!-- <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Secondary Sales (Royalties) Recipient
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="0xabc...123"
                v-model="collection.secondaryRecipient"
              />
            </div> 
          </div>-->
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Secondary Sales (Royalties) Bps [500bps = 5%]
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="500"
                v-model="collection.secondaryBps"
              />
            </div>
          </div>         
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          The Mint
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">                          
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="blockhain"
            >
            Select blockchain and storage
            </label>
            <select v-bind:disabled="isMinted" v-model="collection.blockchain" name="blockhain" id="blockchain">
              <option disabled value="">Mainnets</option>
              <option value="polygon">&nbsp;&nbsp;&nbsp;Polygon (live)</option>
              <option disabled value="ethereum">&nbsp;&nbsp;&nbsp;Ethereum (coming soon)</option>
              <option disabled value="avalanche">&nbsp;&nbsp;&nbsp;Avalanche (coming soon)</option>
              <option disabled value="solana">&nbsp;&nbsp;&nbsp;Solana (coming soon)</option>
              <option disabled value="">Testnets</option>
              <option value="mumbai">&nbsp;&nbsp;&nbsp;Mumbai (test)</option>
              <option disabled value="goerli">&nbsp;&nbsp;&nbsp;Goerli (test)</option>
            </select>
            &nbsp;
            <select v-bind:disabled="isMinted" v-model="collection.storage" name="storage" id="storage">
              <option value="ipfs">IPFS</option>
              <option disabled value="arweave">Arweave (coming soon)</option>
              <option disabled value="avalanche">Filecoin (coming soon)</option>
            </select>
            <br/>
            <div class="mt-4 relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="contractUrlInput"
              >
                Collection Smart Contract Address
              </label>
              <input
                disabled
                type="text"
                id="contractUrlInput" name="contractUrlInput"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-4 lg:w-6/12"
                v-model="collection.address"
                placeholder="Smart contract address generated after mint"
              />
              <a v-if="isMinted" :href="smartContractHref" target="_blank">
                <i class="fas fa-link"></i>
              </a>
              
            </div>
            <div class="mt-4 relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="contractUrlInput"
              >
                Marketplace Contract Address
              </label>
              <input
                disabled
                type="text"
                id="contractUrlInput" name="contractUrlInput"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-4 lg:w-6/12"
                v-model="collection.marketplaceAddress"
                placeholder="Smart contract address generated after mint"
              />
              <a v-if="isMinted" :href="marketplaceHref" target="_blank">
                <i class="fas fa-link"></i>
              </a>
              
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3 mt-3">
   
              <notification-bar ref="notification"></notification-bar>

              <button  
                v-on:click="save()"              
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
              <i class="fas fa-save mr-2"></i> Save
              </button>
              <button 
                v-on:click="mint()"
                class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                <i class="fas fa-code mr-2"></i> {{mintLabel}}
              </button>
              <i v-if="inProgress" class="fas fa-spinner fa-spin"></i>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import NotificationBar from "@/components/Notifications/NotificationBar.vue";
import BlockchainExplorerService from "@/services/BlockchainExplorerService";
import Api from "@/services/Api.js";
export default {
  components: {
    NotificationBar
  },
  data() {
    return {
      collection: {
        name: null,
        symbol: null,
        description: null,
        primaryRecipient: null,
        secondaryRecipient: null,
        secondaryBps: 500,
        blockchain: "mumbai",
        storage: "ipfs",
        address: null,
        marketplaceAddress: null
      },
      inProgress: false
    };
  },
  computed: {
    mintLabel() {
      return `Mint on ${this.collection.blockchain}`
    },
    isMinted() {
      return this.collection != null && this.collection.address;
    },
    isSaved() {
      return this.collection != null && this.collection.id >= 0;
    },
    smartContractHref() {
      if (this.isMinted) {
        let blockchainExplorerService = new BlockchainExplorerService(this.collection.blockchain);
        return blockchainExplorerService.getAddressUrl(this.collection.address);
      }
      return "";
    },
    marketplaceHref() {
      if (this.isMinted) {        
        let blockchainExplorerService = new BlockchainExplorerService(this.collection.blockchain);
        return blockchainExplorerService.getAddressUrl(this.collection.marketplaceAddress);
      }
      return "";
    }

  },
  methods: {
    async mint() {
      if (!this.isSaved) {
        alert("Save the collection before minting")
        return;
      }

      if (this.isMinted) {
        alert("Collection already minted. You can now mint NFTs");
        return;
      }

      if (!confirm("No changes can be made after minting. Are you sure?")) {
        return;
      }
      console.log("deploying collection!");
      try {
        this.inProgress = true;
        const api = Api.from(this.$cookies);
        let res = await api.post(`/api/web3/collections/${this.collection.id}`)
        console.log(res.data);
        this.collection.address = res.data.address;
        this.collection.marketplaceAddress = res.data.marketplaceAddress;
        this.$refs.notification.show("Collection minted. You can now mint individual NFTs");
      } catch (e) {
        this.$refs.notification.showError(e);
      }
      this.inProgress = false;

    },
    async save() {

      if (!this.collection.name || !this.collection.symbol) {
        alert("Name and symbol are required");
        return;
      }
      console.log(`Saving ${JSON.stringify(this.collection)} isNew:${!this.isSaved}`)
      this.inProgress = true;
      try {
        const api = Api.from(this.$cookies);
        if (!this.isSaved) {          
          let res = await api.post("/api/collections", this.collection);
          console.log(res.data);
          this.collection.id = parseInt(res.data);
          this.$refs.notification.show("Collection saved. You can now mint the collection");
        } else {
          if (this.isMinted) {
            if (!confirm("This will update the website, but not the blockchain. Click OK to continue")) {
              this.inProgress = false;
              return;
            }
          }
          let res = api.put(`/api/collections/${this.collection.id}`, this.collection);
          console.log(res.data);
        }
      } catch (e) {
        console.error(e);
      }
      this.inProgress = false;
    }
  },
  async mounted() {
    if (this.$route.params.id == "new") {
      console.log("New collection. Initializing new object")
      this.collection = {
        name: null,
        symbol: null,
        description: null,
        primaryRecipient: null,
        secondaryRecipient: null,
        secondaryBps: 500,
        blockchain: "mumbai",
        storage: "ipfs"
      }
    } else {
      let id = this.$route.params.id;
      console.log(`Loading existing collection: ${id}`)
      const api = Api.from(this.$cookies);
      let res = await api.get(`/api/collections/${id}`);
      console.log(res.data);
      this.collection = res.data;
    }
  }
};
</script>
