<template>
    <div 
        v-if="isVisible" 
        v-bind:class="{'bg-blueGray-500': !isError, 'bg-red-500': isError}"
        class="text-white px-6 py-4 border-0 rounded relative mb-4">
        <span class="text-xl inline-block mr-5 align-middle">
            <i v-if="!isError" class="fas fa-check"></i>
            <i v-if="isError" class="fas fa-minus-circle"></i>
        </span>
        <span class="inline-block align-middle mr-8">
            <b class="capitalize">&nbsp;{{ isError ? "Error" : "Success!"}}</b> {{message}}
        </span>
        <button class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
            <span>×&nbsp;&nbsp;</span>
        </button>
    </div>
</template>
<script>
export default {
  //props: ['message'],
  data() {
    return {
        message: "success",
        isVisible: false,
        isError: false
    }
  },
  methods: {
    show(message, isError, delayMs) {
        this.isError = !!isError;
        if (this.isError) {
            console.error(message);
        } else {
            console.log(message);
        }
        this.message = message;        
        this.isVisible = true;
        setTimeout(() => this.isVisible = false, delayMs || 5000);
    },
    showError(e) {
        if (e.response && e.response.data) {
            this.show(e.response.data, true);
            return;
        }
        this.show(e, true);
    },
    hide() {
        this.isVisible = false;
    }
  }
    
}
</script>
